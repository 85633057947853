import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  //form variables
  name: string;
  email: string;
  institutionName: string;
  message: string;

  formSubmitted = false;

  constructor(public service: LoginService) { }

  ngOnInit() {
  }

  submitForm() {
    if (!this.formSubmitted) {
      this.service.submitFormToFirebase(this.name, this.email, this.institutionName, this.message);
      this.formSubmitted = true;
    }
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-header',
  templateUrl: './landing-header.component.html',
  styleUrls: ['./landing-header.component.scss']
})
export class LandingHeaderComponent implements OnInit {

  mobile = false;

  constructor() { }

  ngOnInit() {
  }

  onHamburgerClick() {
    var dropdown = document.getElementById("hamburger-content");
    var menu = document.getElementById("menu");
    if (dropdown.style.display === "block") {
      dropdown.style.display = "none";
      menu.style.color = "#5DBFC5";
    } else {
      dropdown.style.display = "block";
      menu.style.color = "#1F8E94";
    }
  }

}

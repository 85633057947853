import {AfterViewInit, Component, ElementRef, NgZone, OnInit, Renderer2, SimpleChanges} from '@angular/core';
import {LoginService} from '../login.service';
import {HttpClient} from '@angular/common/http';
import {AngularFirestore} from '@angular/fire/firestore';
import {MapsAPILoader} from '@agm/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {MainComponent} from '../main/main.component';
import { interval, Subscription } from 'rxjs';


@Component({
  selector: 'app-logged-in',
  templateUrl: './logged-in.component.html',
  styleUrls: ['./logged-in.component.scss']
})
export class LoggedInComponent implements OnInit, AfterViewInit {


  constructor(private ngZone: NgZone,
              public fb: FormBuilder,
              public service: LoginService,
              private firestore: AngularFirestore,
              private http: HttpClient,
              private mapsAPI: MapsAPILoader,
              private route: ActivatedRoute,
              private mainComponent: MainComponent,
              private elRef: ElementRef,
              private renderer: Renderer2 // good practice to use Renderer2 for changing properties
  ) {
    let info;
    this.route.params.subscribe(params => info = params);
    this.code = info.code;
  }

  clinicAddress; // the clinicInfo document from firebase
  printableClinicAddress;

  userLat: number;
  userLong: number;

  // style variables
  headerDisplay;
  toastDisplay;
  nicerStatus = '';

  // colours for the commute options
  carColor;
  busColor;
  walkColor;
  carBGColor;
  busBGColor;
  walkBGColor;

  // eta status of the patient
  // userStatus = "no-action";

  // modal variables
  modalDisplay = 'none';
  formModalDisplay = 'none';
  infoFormDisplay = 'none';

  selectedStatus: string;

  code = '';
  Address;
  name: string;
  phone;
  dob;
  reason;
  time;
  timeSub;
  timeUntil;
  apptTime;

  // string and icon saying the submission status of the forms
  covidFormWasSubmittedStr = 'Form Submitted'; // "Form submitted or Form not submitted"
  covidFormWasSubmittedIcon = 'check'; // "check" or "close"

  infoFormWasSubmittedStr = 'Form not submitted'; // "Form submitted or Form not submitted"
  infoFormWasSubmittedIcon = 'close'; // "check" or "close"

  // the icon which shows in the commute time block
  commuteIcon = '';

  // individual clinic special features list
  CovidFormClinics = {
    // "qsd":'',
    // "qsd_dev":'',
    // "kis":''
  };
  YourInfoFormClinics = {
    qsd: '',
    qsd_dev: ''
  };

  // Mandatory Preparations variables
  selectedImagingType = 'Select imaging type';
  selectedImagingTypeOld = this.selectedImagingType;
  selectedImagingArea = 'Select an imaging type first';

  // Info form variables
  HCLastTwoVals = '';
  HCExpiryDate = 'yyyy-mm-dd';
  patientDoesNotHaveHC = false;

  // Info form error warnings
  HCLastTwoValsError = false;
  HCLastTwoValsNotEnoughtCharsError = false;
  HCExpiryDateBlankFieldError = false;

  // Info form "on file" information
  onFileEmailAddress: string;
  onFilePhoneNumber: string;
  onFileHomeAddress: string;

  patientInfoOnRecordIsIncorrect = false;

  car_commute;
  bus_commute;
  walk_commute;
  final_commute;
  userExists = true;

  public searchElementRef: ElementRef;

  commuteOptionsForm = this.fb.group({
    name: ['']
  });

  private youIcon = {
    url: '../../../../assets/you.svg',
    scaledSize: {
      height: 40,
      width: 40
    }
  };

  private clinicIcon = {
      url: '../../../../assets/clinic.svg',
    scaledSize: {
      height: 50,
      width: 50
    }
  };


  goodToSubmitCovidForm = false; // used to keep track if covid form can be submitted or not

  onSubmit() {
    alert(JSON.stringify(this.commuteOptionsForm.value));
  }

  async ngOnInit() {

    this.time = new Date();

    // update the time every second
    const timeSource = interval(1000); // every 1 seconds (1000 milliseconds)
    this.timeSub = timeSource.subscribe(val => this.updateTime());

    let info;
    this.route.params.subscribe(params => info = params);
    if (!('code' in info)) {
      // this shows when you do not have the link, from Main log in
      this.userExists = true;
      if (await this.service.getStatus() == 'text-sent') {
        await this.service.updateStatus('website-accessed');
      }
      await this.getUserLocation();
      if (await this.service.getCommuteType() === 'bus') {
        this.busCommuteSettings();
      } else if (await this.service.getCommuteType() === 'car') {
        this.carCommuteSettings();
      } else if (await this.service.getCommuteType() === 'walk') {
        this.walkCommuteSettings();
      } else {
        this.carCommuteSettings();
      }
      this.headerDisplay = 'none'; // if the user did not take the link, remove it
    } else {
      // this is from the link
      this.service.clinic = info.name.replace('+', ' '); // updates which clinic this is for via the firebase
      this.code = info.code;
      await this.service.loggedCheckUser(info.code);
      if (this.service.loggin) {
        this.userExists = true;
        if (await this.service.getStatus() == 'text-sent') {
          await this.service.updateStatus('website-accessed');
        }
        await this.getUserLocation();
        if (await this.service.getCommuteType() === 'bus') {
          this.busCommuteSettings();
        } else if (await this.service.getCommuteType() === 'car') {
          this.carCommuteSettings();
        } else if (await this.service.getCommuteType() === 'walk') {
          this.walkCommuteSettings();
        } else {
          this.carCommuteSettings();
        }
      } else {
        this.userExists = false;
      }
    }

    // only run this block of code if the clinic has a covid form
    if (this.service.clinic in this.CovidFormClinics) {

      // checks if the modal/form should be open/was submitted
      const covidFormAnswers = await this.service.getFormAnswers();
      if (covidFormAnswers == '------') {
        // open the modal
        this.formModalDisplay = 'block';
        this.covidFormWasSubmittedStr = 'Form not submitted';
        this.covidFormWasSubmittedIcon = 'close';

        // set the background to not scroll when the modal is open
        this.renderer.setStyle(
          this.elRef.nativeElement.parentElement.parentElement,
          'overflow',
          'hidden'
        );
      } else { // if it was submitted, then fill the form with the previous answers
        (document.getElementById('q1yes') as HTMLInputElement).checked = covidFormAnswers[0] == 'y';
        (document.getElementById('q2yes') as HTMLInputElement).checked = covidFormAnswers[1] == 'y';
        (document.getElementById('q3yes') as HTMLInputElement).checked = covidFormAnswers[2] == 'y';
        (document.getElementById('q4yes') as HTMLInputElement).checked = covidFormAnswers[3] == 'y';
        (document.getElementById('q6yes') as HTMLInputElement).checked = covidFormAnswers[5] == 'y';
        (document.getElementById('q1no') as HTMLInputElement).checked = covidFormAnswers[0] == 'n';
        (document.getElementById('q2no') as HTMLInputElement).checked = covidFormAnswers[1] == 'n';
        (document.getElementById('q3no') as HTMLInputElement).checked = covidFormAnswers[2] == 'n';
        (document.getElementById('q4no') as HTMLInputElement).checked = covidFormAnswers[3] == 'n';
        (document.getElementById('q6no') as HTMLInputElement).checked = covidFormAnswers[5] == 'n';
      }
    }

    // only run this block of code if the clinic has an info form
    if (this.service.clinic in this.YourInfoFormClinics) {

      // check status of the info form, and setup site accordingly
      try { // this field may not exist yet. if it doesn't then the form was not submitted
        if (await this.service.didPatientSubmitInfoForm()) {
          this.infoFormWasSubmittedStr = 'Form submitted';
          this.infoFormWasSubmittedIcon = 'check';
        }
      } catch {
        // do nothing
      }

      // if they submitted their HC info, update the form to reflect it
      const HCExpiryData = await this.service.getHCExpiry();
      const HCLastTwoLettersData = await this.service.getHCLastTwoVals();
      if (!(HCExpiryData == '' || HCLastTwoLettersData == '')) {
        // if statement to make sure they submitted something
        this.HCExpiryDate = HCExpiryData;
        this.HCLastTwoVals = HCLastTwoLettersData;
      }

      // get their info on file
      const emailAddressData = await this.service.getPatientEmail();
      const phoneNumberData = await this.service.getPatientPhoneNumber();
      const homeAddressData = await this.service.getPatientHomeAddress();

      this.onFileEmailAddress = this.checkOnFileInfo(emailAddressData);
      this.onFilePhoneNumber = this.checkOnFileInfo(phoneNumberData);
      this.onFileHomeAddress = this.checkOnFileInfo(homeAddressData);

      // checkboxes
      // no field on FB for the "no HC" checkbox
      this.patientInfoOnRecordIsIncorrect = !(await this.service.getValidInfo());

      // check if the info form should be open on startup
      if (!(this.service.clinic in this.CovidFormClinics) && this.infoFormWasSubmittedStr != 'Form submitted') {
        this.openInfoFormModal();
      }
    }

    this.printableClinicAddress = await this.service.getClinicAddress();
    this.clinicAddress = this.printableClinicAddress.replaceAll(' ', '+'); // get the clinic info document
  }

  async ngAfterViewInit() {

  }

  async autocompleteFocus() {
    await this.mapsAPI.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(document.getElementById('search') as HTMLInputElement, {
        types: []
      });
      autocomplete.addListener('place_changed', () => {
        // ngZone is used to detect any changes (by angular)
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.service.updateAddress(place.formatted_address);
          this.userLong = place.geometry.location.lng();
          this.userLat = place.geometry.location.lat();
          this.getCommute();
        });
      });
    });
  }

  getHour(time) {
    if (time >= 0) {
      return Math.floor(time / 60);
    } else {
      return Math.ceil(time / 60);
    }
  }

  getMinute(time) {
    const minute =  Number((time % 60).toPrecision(2));
    if (minute < 10) {
      return minute;
    } else {
      return minute;
    }
  }

  getCommuteTime(time) {
    const commute_time = this.getHour(time) + ':' + this.twoDigits(this.getMinute(time));
    if (time == undefined) {
      return 'N/A';
    }
    return commute_time;
  }

  getDelay(time, predicted) {
    try {
      const appointment_time = new Date();
      const time_split = time.split(':');
      appointment_time.setHours(parseInt(time_split[0]));
      appointment_time.setMinutes(parseInt(time_split[1]));
      appointment_time.setHours(appointment_time.getHours() + this.getHour(parseInt(predicted)));
      appointment_time.setMinutes(appointment_time.getMinutes() + this.getMinute(parseInt(predicted)));
      const current_date = new Date();
      if (current_date.getTime() > appointment_time.getTime()) {
        // return "Appointment should have started"
        return '0:00';
      }
      const final_time_difference = appointment_time.getTime() - current_date.getTime();
      let final_minute;
      if ((final_time_difference % 3600000 / 60000) < 10) {
        final_minute = '0' + Math.ceil(final_time_difference % 3600000 / 60000).toString();
      } else {
        final_minute = Math.ceil(final_time_difference % 3600000 / 60000).toString();
      }
      return (Math.floor(final_time_difference / 3600000).toString() + ':' + final_minute);
    } catch (e) {
      // consol.log'("just give it a minute");
    }
  }

  noNegative(num) {
    if (num < 0) {
      return 0;
    }
    return num;
  }

  async updateTime() {
    /**
     * sets the time variable to a new date
     */
    this.time = new Date();
    // let currentTimeInMin = this.time.getHours() * 60 + this.time.getMinutes();
    // console.log("stuff")
    // console.log(this.apptTime);
    // let apptTimeInMin = Number(this.apptTime.substring(0, 2)) * 60 + Number(this.apptTime.substring(3, 5));
    //
    // console.log(currentTimeInMin);
    // console.log(apptTimeInMin);

  }

  getTimeUntilAppt(apptTime) {
    const apptTimeInMin = Number(apptTime.substring(0, 2)) * 60 + Number(apptTime.substring(3, 5));
    const currentTimeInMin = this.time.getHours() * 60 + this.time.getMinutes();
    const diffInTime = apptTimeInMin - currentTimeInMin;

    const timeUntilHour = this.getHour(diffInTime);
    let timeUntilMin = this.getMinute(diffInTime);

    let newTimeUntilHour = timeUntilHour + '';

    if (timeUntilMin < 0) {
      timeUntilMin *= -1; // dont want min to be negative

      if (timeUntilHour >= 0) {
        newTimeUntilHour = '-' + timeUntilHour; // DO want hour to be negative
      }
    }

    return newTimeUntilHour + ':' + this.twoDigits(timeUntilMin);
  }

  twoDigits(num) {
    /**
     * Pass in a num and it returns that same num with at least 2 digits (putting a 0 in front)
     */
    if (num < 10) {
      return '0' + num;
    } else {
      return num;
    }
  }

  private getCommute() {
    this.mapsAPI.load().then(() => {
      const origin =  new google.maps.LatLng(this.userLat, this.userLong);
      const dest = new google.maps.LatLng(this.service.clinicLat, this.service.clinicLng);
      const service = new google.maps.DistanceMatrixService();
      /*TODO this take a little tiny bit of time to load, consider replacing the output of this with it's own loader*/
      /*instead of using the large full screen loader*/
      service.getDistanceMatrix({
        avoidTolls: true,
        avoidHighways: false,
        origins: [origin],
        destinations: [dest],
        travelMode: google.maps.TravelMode.DRIVING,
        // durationInTraffic: true
        }, (results: any) => {
        this.car_commute = Math.round(results.rows[0].elements[0].duration.value / 60);
        this.final_commute = this.car_commute;
        this.service.setCommute('carCommute', this.car_commute);
      });
    });
    this.mapsAPI.load().then(() => {
      const origin =  new google.maps.LatLng(this.userLat, this.userLong);
      const dest = new google.maps.LatLng(this.service.clinicLat, this.service.clinicLng);
      const service = new google.maps.DistanceMatrixService();
      service.getDistanceMatrix({
        avoidTolls: true,
        avoidHighways: false,
        origins: [origin],
        destinations: [dest],
        travelMode: google.maps.TravelMode.TRANSIT,
        // durationInTraffic: true
      }, (results: any) => {
        if (results.rows[0].elements[0].status != 'ZERO_RESULTS') {
          this.bus_commute = Math.round(results.rows[0].elements[0].duration.value / 60);
          this.service.setCommute('busCommute', this.bus_commute);
        } else {
          this.service.setCommute('busCommute', 0);
        }
      });
    });
    this.mapsAPI.load().then(() => {
      const origin =  new google.maps.LatLng(this.userLat, this.userLong);
      const dest = new google.maps.LatLng(this.service.clinicLat, this.service.clinicLng);
      const service = new google.maps.DistanceMatrixService();
      service.getDistanceMatrix({
        avoidTolls: true,
        avoidHighways: false,
        origins: [origin],
        destinations: [dest],
        travelMode: google.maps.TravelMode.WALKING,
        // durationInTraffic: true
      }, (results: any) => {
        this.walk_commute = Math.round(results.rows[0].elements[0].duration.value / 60);
        this.service.setCommute('walkCommute', this.walk_commute);
      });
    });
  }

  // todo make the getCommute a single API call instead of three API calls.

  async getUserLocation() {
    // await this.mapsAPI.load().then(()=> {
    //   let autocomplete = new google.maps.places.Autocomplete(<HTMLInputElement>document.getElementById("search"), {
    //     types: []
    //   });
    //   console.log("whay");
    //   autocomplete.addListener('place_changed', () => {
    //     //ngZone is used to detect any changes (by angular)
    //     this.ngZone.run(() => {
    //       const place: google.maps.places.PlaceResult = autocomplete.getPlace();
    //       if (place.geometry === undefined || place.geometry === null) {
    //         return;
    //       }
    //       this.service.updateAddress(place.formatted_address);
    //       this.userLong = place.geometry.location.lng();
    //       this.userLat = place.geometry.location.lat();
    //       console.log(this.userLat);
    //       this.getCommute();
    //     })
    //   })
    // });
    /*
    function that return the user's position and then reloads the map
     */
   if (navigator.geolocation) {
     await navigator.geolocation.getCurrentPosition(async position => {
        const potLat = position.coords.latitude;
        const potLong = position.coords.longitude;
        // if lat and lng != the service address, then getcommute - else just leave it for later.
        this.Address = await this.service.getAddress();
        if (this.Address != '') {
          await this.getLongLat(this.Address);
          if (Math.abs(this.userLat - potLat) > 0.01 && Math.abs(this.userLong - potLong) > 0.01) {
            this.userLong = potLong;
            this.userLat = potLat;
            await this.getAddress();
            await this.getCommute();
          }
          document.getElementById('loader-container').style.display = 'none';
        } else {
          this.userLong = potLong;
          this.userLat = potLat;
          await this.getAddress();
          await this.getCommute();
          document.getElementById('loader-container').style.display = 'none';
        }
      }, async error => {
       const addr = await this.service.getAddress();
       if (addr == '') {
         this.userLat = this.service.clinicLat;
         this.userLong = this.service.clinicLng;

         await this.getAddress();
         await this.getCommute();
         document.getElementById('loader-container').style.display = 'none';
       } else {
         this.Address = await addr;
         await this.getLongLat(addr);
         document.getElementById('loader-container').style.display = 'none';
       }
      });
    }
  }

  private async getLongLat(addr: string) {
    await this.mapsAPI.load().then(() => {
      const service = new google.maps.Geocoder();
      service.geocode({
        address: addr
      }, async results => {
        this.userLat = results[0].geometry.location.lat();
        this.userLong = results[0].geometry.location.lng();
        if (await this.service.getCarCommute() != '') {
          await this.getCommute();
        }
      });
    });
  }

  private async getAddress() {
    await this.mapsAPI.load().then(() => {
      const service = new google.maps.Geocoder();
      service.geocode({
        location: {lat: this.userLat, lng: this.userLong}
      }, results => {
        this.Address = results[0].formatted_address;
      });
    });
  }

  private toastOpen() {
    /*
    function that opens the toast
     */
    this.toastDisplay = '-webkit-flex';
  }

  private toastClose() {
    /*
    function that closes the toast
     */
    this.toastDisplay = 'none';
  }

  selectCar() {
    if (this.commuteIcon != 'directions_car') {
      this.carCommuteSettings();
      this.service.setCommuteType('car');
    }
  }

  carCommuteSettings() {
    this.carColor = '#FCFFFF';
    this.busColor = '#414141';
    this.walkColor = '#414141';
    this.carBGColor = '#5DBFC5';
    this.busBGColor = '#EEEEEE';
    this.walkBGColor = '#EEEEEE';
    this.final_commute = this.car_commute;
    this.commuteIcon = 'directions_car';
  }

  selectBus() {
    if (this.commuteIcon != 'directions_bus') {
      this.busCommuteSettings();
      this.service.setCommuteType('bus');
    }
  }

  busCommuteSettings() {
    this.carColor = '#414141';
    this.busColor = '#FCFFFF';
    this.walkColor = '#414141';
    this.carBGColor = '#EEEEEE';
    this.busBGColor = '#5DBFC5';
    this.walkBGColor = '#EEEEEE';
    this.final_commute = this.bus_commute;
    this.commuteIcon = 'directions_bus';
  }

  selectWalk() {
    if (this.commuteIcon != 'directions_walk') {
      this.walkCommuteSettings();
      this.service.setCommuteType('walk');
    }
  }

  walkCommuteSettings() {
    this.carColor = '#414141';
    this.busColor = '#414141';
    this.walkColor = '#FCFFFF';
    this.carBGColor = '#EEEEEE';
    this.busBGColor = '#EEEEEE';
    this.walkBGColor = '#5DBFC5';
    this.final_commute = this.walk_commute;
    this.commuteIcon = 'directions_walk';
  }

  embeddedIn(x, y) {
    // does the in operator so we can embed it in an element in html
    return x in y;
  }

  // textNotif = document.getElementById('textNotif');


  textNotif(values: any) {
    this.service.changeTextNotif(values.currentTarget.checked);

  }

  // emailNotif(values: any){
  //   this.service.changeEmailNotif(values.currentTarget.checked)
  // }

  replace_(str) {
    if (str == 'website-accessed') {
      return '';
    }
    return this.formatStatus(str);
  }

  formatStatus(str) {
    return str.replace(/-/g, ' ').replace(str[0], str[0].toUpperCase());
  }


  // modal functions
  closeModal() {this.modalDisplay = 'none'; }
  openModal() {this.modalDisplay = 'block'; }
  openCovidFormModal() {
    this.formModalDisplay = 'block';
    this.renderer.setStyle(
      this.elRef.nativeElement.parentElement.parentElement,
      'overflow',
      'hidden'
    );
  }
  openInfoFormModal() {
    this.infoFormDisplay = 'block';
    this.renderer.setStyle(
      this.elRef.nativeElement.parentElement.parentElement,
      'overflow',
      'hidden'
    );
  }
  closeFormModal() {
    this.formModalDisplay = 'none';
    this.renderer.setStyle(
      this.elRef.nativeElement.parentElement.parentElement,
      'overflow',
      'auto'
    );
  }
  closeInfoFormModal() {
    this.infoFormDisplay = 'none';
    this.renderer.setStyle(
      this.elRef.nativeElement.parentElement.parentElement,
      'overflow',
      'auto'
    );
  }

  selectStatus(newStatus) {
    this.selectedStatus = newStatus;
    this.nicerStatus = this.formatStatus(this.selectedStatus);
    this.openModal();
  }

  yesSelect() {
    this.service.updateStatus(this.selectedStatus);
  }

  // refreshes the screen when the user clicks the logo
  refresh() {
    window.location.reload();
  }
  screeningQuestionClicked() {
    /**
     * is called whenever the user updates the prescreening form
     * enables or disables the submit button depending on the status of the form
     *
     * i needed to somehow know when all the options had been filled before the form
     * was submitted
     */
    // check if all the questions have been answered
    const q1Answered = (document.getElementById('q1yes') as HTMLInputElement).checked
        || (document.getElementById('q1no') as HTMLInputElement).checked;
    const q2Answered = (document.getElementById('q2yes') as HTMLInputElement).checked
        || (document.getElementById('q2no') as HTMLInputElement).checked;
    const q3Answered = (document.getElementById('q3yes') as HTMLInputElement).checked
        || (document.getElementById('q3no') as HTMLInputElement).checked;
    const q4Answered = (document.getElementById('q4yes') as HTMLInputElement).checked
        || (document.getElementById('q4no') as HTMLInputElement).checked;
    // let q5Answered = (<HTMLInputElement>document.getElementById("q5yes")).checked
    //     || (<HTMLInputElement>document.getElementById("q5no")).checked
    const q5Answered = true; // temporary removal of q5
    const q6Answered = (document.getElementById('q6yes') as HTMLInputElement).checked
        || (document.getElementById('q6no') as HTMLInputElement).checked;

    // check if the confirm button has been ticked
    const confirmTrustChecked = (document.getElementById('confirmTrust') as HTMLInputElement).checked;

    if (q1Answered && q2Answered && q3Answered && q4Answered && q5Answered && q6Answered && confirmTrustChecked) {
      // if all the questions are answered and confirm button has a check in it
      document.getElementById('submitcovid').classList.remove('covid-form-submit-disabled');
      this.goodToSubmitCovidForm = true;
    } else {
      document.getElementById('submitcovid').classList.add('covid-form-submit-disabled');
      this.goodToSubmitCovidForm = false;
    }
  }

  // handling the submission of the covid screening form
  submitForm() {
    if (!this.goodToSubmitCovidForm) { // if we are not good to submit the covid form, do nothing
      return;
    }

    let prescreeningPassed = 'passed';
    let prescreeningAnswersString = '';

    // get all the answers from the form and push them to a list
    const formAnswers = [];
    formAnswers.push((document.getElementById('q1yes') as HTMLInputElement).checked);
    formAnswers.push((document.getElementById('q2yes') as HTMLInputElement).checked);
    formAnswers.push((document.getElementById('q3yes') as HTMLInputElement).checked);
    formAnswers.push((document.getElementById('q4yes') as HTMLInputElement).checked);
    // formAnswers.push((<HTMLInputElement>document.getElementById("q5yes")).checked);
    formAnswers.push('q5'); // temporary removal of q5
    formAnswers.push((document.getElementById('q6yes') as HTMLInputElement).checked);

    // if any answers are missing, we dont know the outcome
    if (formAnswers.length != 6) {
      prescreeningPassed = 'unknown';
    } else if (formAnswers.length == 6) {
      let i;
      for (i = 0; i < formAnswers.length; i++) {
        if (formAnswers[i] == true) {
          prescreeningPassed = 'not-passed';
          prescreeningAnswersString = prescreeningAnswersString + 'y';
        } else if (formAnswers[i] == false) {
          prescreeningAnswersString = prescreeningAnswersString + 'n';
        } else if (formAnswers[i] == 'q5') { // temporary removal of q5
          prescreeningAnswersString = prescreeningAnswersString + '-';
        }
      }
    }

    this.covidFormWasSubmittedStr = 'Form submitted';
    this.covidFormWasSubmittedIcon = 'check';

    this.service.updateForm(prescreeningPassed, prescreeningAnswersString);
    this.openInfoFormModal();
    this.closeFormModal();
  }


  doesNotHaveHealthcardChecked() {
    if (this.patientDoesNotHaveHC) {
      this.patientDoesNotHaveHC = false;
    } else {
      this.patientDoesNotHaveHC = true;

      // remove any error messages
      this.HCLastTwoValsError = false;
      this.HCLastTwoValsNotEnoughtCharsError = false;
      this.HCExpiryDateBlankFieldError = false;
    }
  }

  submitInfoForm() {
    let valid = true;

    // remove any error messages
    this.HCLastTwoValsError = false;
    this.HCLastTwoValsNotEnoughtCharsError = false;
    this.HCExpiryDateBlankFieldError = false;

    // check for HCLastTwoValsNotEnoughtCharsError
    if (this.HCLastTwoVals.length != 2) {
      // if there is less than 2 chars
      valid = false;
      this.HCLastTwoValsNotEnoughtCharsError = true;
    }

    // check for HCLastTwoValsError
    if (this.HCLastTwoVals != '') {
      if (!(this.isLetter(this.HCLastTwoVals.charAt(0)))
        || (!(this.isLetter(this.HCLastTwoVals.charAt(1))) && (this.HCLastTwoVals.charAt(1) != this.HCLastTwoVals.charAt(2)))) {
        // if either of the chars is not a letter
        valid = false;
        this.HCLastTwoValsError = true;
      }
    }

    // check for HCExpiryDateBlankFieldError
    if (this.HCExpiryDate == 'yyyy-mm-dd') {
      valid = false;
      this.HCExpiryDateBlankFieldError = true;
    }

    if (this.patientDoesNotHaveHC) {
      this.service.setInfoFormSubmittedStatusTrue();
      this.infoFormDisplay = 'none'; // close the form
      this.infoFormWasSubmittedStr = 'Form submitted';
      this.infoFormWasSubmittedIcon = 'check';

      // remove any error messages
      this.HCLastTwoValsError = false;
      this.HCLastTwoValsNotEnoughtCharsError = false;
      this.HCExpiryDateBlankFieldError = false;
    } else if (valid) {
      this.service.setHCExpiry(this.HCExpiryDate);
      this.service.setHCLastTwoVals(this.HCLastTwoVals.toLowerCase());
      this.service.setInfoFormSubmittedStatusTrue();
      this.infoFormDisplay = 'none'; // close the form
      this.infoFormWasSubmittedStr = 'Form submitted';
      this.infoFormWasSubmittedIcon = 'check';
    }
  }

  isLetter(str) {
    return /[a-zA-Z]/.test(str);
  }

  // when the "my info is not correct" button on the info form changes
  async changeInfoButtonClicked() {
    this.patientInfoOnRecordIsIncorrect = !this.patientInfoOnRecordIsIncorrect;
    await this.service.setValidInfo(!this.patientInfoOnRecordIsIncorrect);
  }

  // processes the email, home address and phone number fields for display
  checkOnFileInfo(onFileInfo) {
    if (
         onFileInfo.toLowerCase() == undefined
      || onFileInfo.toLowerCase() == 'none'
      || onFileInfo.toLowerCase() == ''
      || onFileInfo.toLowerCase() == '-'
    ) {
      return 'Not on file';
    }
    return onFileInfo;
  }

  // simply for embedding into html and testing. DO NOT USE THIS IN ACTUAL CODE
  print() {
    // consol.log'(this.selectedImagingType);
  }

  imagingTypeSelectorClicked() {
    if (this.selectedImagingType != this.selectedImagingTypeOld) {
      if (this.selectedImagingType != 'Select imaging type') {
        this.selectedImagingArea = 'Select imaging area';
      } else {
        this.selectedImagingArea = 'Select an imaging type first';
      }
      this.selectedImagingTypeOld = this.selectedImagingType;
    }
  }

  preparationNeededMapper(exam_list, room_name) {

    let finalPrepString = '';

    exam_list = exam_list.split('---'); // split the whole string on "---"
    room_name = room_name.split('---'); // room_name and exam_list should be the same length
    for (let i = 0; i < exam_list.length; i++) {
      let ltdabdomen = false;
      let abdomen = false;
      let ltdpelvis = false;
      let pelvis = false;
      let obstetrical = false;
      let aorta = false;
      let lowerArterial = false;
      let kidney = false;
      let BMD = false;
      let xray = false;

      // parse exam_list
      exam_list[i] = exam_list[i].split('&comma;'); // split the substrings on "&comma;"
      for (let j = 0; j < exam_list[i].length; j++) { // check which examinations are present
        if (exam_list[i][j].includes('Limited Abdomen')) { ltdabdomen = true; }
        if (exam_list[i][j].includes('Abdomen')) { abdomen = true; }
        if (exam_list[i][j].includes('Limited Pelvis')) { ltdpelvis = true; }
        if (exam_list[i][j].includes('Pelvis')) { pelvis = true; }
        if (exam_list[i][j].includes('Kidneys')) { kidney = true; }
        if (exam_list[i][j].includes('OB') || exam_list[i][j].includes('Transvaginal') || (exam_list[i][j].includes('Pelvis'))) { obstetrical = true; }
        if (exam_list[i][j].includes('Aorta')) { aorta = true; }
        if (exam_list[i][j].includes('Lower Limb Arterial')) { lowerArterial = true; }
        if (exam_list[i][j].includes('BMD')) { BMD = true; }
      }
      // parse room_name
      if (room_name[i] == '1') { xray = true; }
      let toAddToFinalPrepString = '';
      if (xray) {
        toAddToFinalPrepString = 'X-Ray: No Preparation required';
        toAddToFinalPrepString += '<br/>';
      } else if (ltdabdomen && pelvis) {
        toAddToFinalPrepString = 'KUB: 4 glasses of water 1H before appointment and hold';
        toAddToFinalPrepString += '<br/>';
      } else if (ltdabdomen && ltdpelvis) {
        toAddToFinalPrepString = 'KUB: 4 glasses of water 1H before appointment and hold';
        toAddToFinalPrepString += '<br/>';
      } else if (abdomen && pelvis) {
        toAddToFinalPrepString = 'Abdomen & Pelvis: 6H fast + 4 glasses of water 1H before appointment and hold';
        toAddToFinalPrepString += '<br/>';
      } else if (abdomen && !(ltdabdomen)) {
        toAddToFinalPrepString = 'Abdomen: 6H fast : No food, no drink';
        toAddToFinalPrepString += '<br/>';
      } else if (kidney) {
        toAddToFinalPrepString = 'Kidneys/Bladder: 4 glasses of water 1H before appointment and hold';
        toAddToFinalPrepString += '<br/>';
      } else if (obstetrical) {
        toAddToFinalPrepString = 'Obstetrical/Pelvis/Transvaginal: 4 glasses of water 1H before appointment and hold';
        toAddToFinalPrepString += '<br/>';
      } else if (aorta) {
        toAddToFinalPrepString = 'Aorta/AAA: 6H fast : No food, no drink';
        toAddToFinalPrepString += '<br/>';
      } else if (lowerArterial) {
        toAddToFinalPrepString = 'Lower Aterial: Fast 6 hours. No compression stockings. Patient must be mobile';
        toAddToFinalPrepString += '<br/>';
      } else if (BMD) {
        toAddToFinalPrepString = 'BMD: No calcium tablet 24H before appointment. Patient can not go for an CT scan or Barium Swallow test 2 weeks prior. Avoid metal in clothes';
        toAddToFinalPrepString += '<br/>';
      }
      finalPrepString += toAddToFinalPrepString;
    }

    if (finalPrepString == '') {
      return 'No Preparation required';
    }
    return finalPrepString;
  }

  userLatAndLongToString() {
    if (this.userLat == undefined || this.userLong == undefined) {
      return;
    }
    return this.userLat + ',' + this.userLong;
  }

}

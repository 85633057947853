import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-redirect-landing',
  templateUrl: './redirect-landing.component.html',
  styleUrls: ['./redirect-landing.component.scss']
})
export class RedirectLandingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.location.replace('https://waivethewait.ca');
  }

}

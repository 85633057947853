import {Component, OnInit} from '@angular/core';
import {LoginService} from 'src/app/login.service';
import {ActivatedRoute, Router} from "@angular/router"
import { LoggedInComponent } from "../logged-in/logged-in.component";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  // email: string;
  code: string;
  phone: number;
  name: string;
  title = 'WaiveTheWait';
  modalDisplay = "none";
  clinicExists = true;
  phone1: number;
  joinQueueDisplay = "block";
  joinQueueFormDisplay = "flex";
  clinicClosedNotificationDisplay = "none";
  JoinQueueNotClickedDisplay = "block";
  JoinQueueClickedDisplay = "none";
  termsOfService = false;
  hasEnteredQueue = false;

  constructor(
    public service: LoginService,
    public router: Router,
    private route: ActivatedRoute,
  ){}


  async ngOnInit() {
    this.route.params.subscribe(params=>this.service.clinic=params["name"].replace("+", " "));
    await this.service.checkURL();
    if(this.service.loggin){
      this.clinicExists = true;

    }
    else{
      this.clinicExists = false;
    }

    if (await this.service.getIsClinicClosed() == true) { // if closed
      this.joinQueueDisplay = "none";
      this.joinQueueFormDisplay = "none";
      this.clinicClosedNotificationDisplay = "block";
    }
  }

  info = undefined;
  found = false;
  hide = false;
  bad = false;
  bad_wording;


  closeFormModal() {this.modalDisplay = "none";}

  async signIn() {
    try {
      this.code = this.code.replace(/\s/g, '');
      this.code = this.code.toLowerCase();
      await this.service.checkUser(this.code, this.phone1);
      if (this.service.loggin) {
        this.service.code = this.code;
        this.info = await this.service.serviceSignIn(this.code);
        this.service.getUser(this.code);
        this.found = this.info != undefined;
        this.hide = true;
        this.clinicExists = true;
      }
      else{
        this.hide=true;
      }
    } catch (e) {
      // consol.log'("Nothing we can sign in with");
      this.hide = true;
    }

  }

  async join() {
    let phoneCheck = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    // if name wasnt entered
    if(this.name == undefined || this.name.length==0){
      this.bad_wording = "Please enter your name.";
      this.bad = true;
    }
    // if everything is correct
    else if (this.phone !== undefined && this.phone.toString().replace(" ", "").match(phoneCheck)) {
      //separated into two if statements so it doesnt go to the else block when clicked multiple times
      if (!this.hasEnteredQueue) {
        // make it unable to re-enter this block of code
        this.hasEnteredQueue = true;
        // display the loader
        this.JoinQueueClickedDisplay = "block";
        // undisplay the button text
        this.JoinQueueNotClickedDisplay = "none";

        this.bad = false;
        await this.service.joinQueue(this.phone, this.name);
        this.found = true;
      }
    }
    //if phone number is invalid
    else{
      this.bad_wording = "Please enter your phone number in the right format.";
      this.bad = true;
    }
  }

  //opens the modal
  openModal() {
    this.modalDisplay = "block";
  }

  //closes the modal
  closeModal() {
    this.modalDisplay = "none";
  }

  //turns the "Join Queue" button on/off every time the terms checkbox is clicked
  termsClicked(originOfClick) {
    if (originOfClick == 'termsCheckbox') {
      document.getElementById("joinbutton").classList.toggle("disabled");
      this.termsOfService = this.termsOfService == false;
    }
    else if (originOfClick == 'modalConfirm') {
      if (this.termsOfService == false) {
        document.getElementById("joinbutton").classList.toggle("disabled");
        this.termsOfService = true;
      }
    }
  }

  //refreshes the screen when the user clicks the logo
  refresh() {
    window.location.reload();
  }
}

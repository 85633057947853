import {Component, OnInit} from '@angular/core';
import { filter } from 'rxjs/operators';
import {NavigationEnd, Router} from "@angular/router";
import * as AOS from 'aos';
declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(public router: Router){
    const navEndEvent$ = router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((event: NavigationEnd) => {
      gtag('config', 'G-4DL392339D', {'page_path': event.urlAfterRedirects});
    });
  }
  ngOnInit(): void {
    AOS.init({
        duration: 1200,
        once: true
      });
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-campaign-demo',
  templateUrl: './campaign-demo.component.html',
  styleUrls: ['./campaign-demo.component.scss']
})
export class CampaignDemoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

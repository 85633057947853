import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login.service';

@Component({
  selector: 'app-landing-footer',
  templateUrl: './landing-footer.component.html',
  styleUrls: ['./landing-footer.component.scss']
})
export class LandingFooterComponent implements OnInit {

  constructor(public service: LoginService) { }

  email: string;
  previousEmails = ['']; // stores the last submitted emails to check for double submissions
  displayConfirm = "none";

  ngOnInit() {
  }


  /**
   * code retrieved from https://www.w3resource.com/javascript/form/email-validation.php
   * edited slightly by us
   */
  ValidateEmail(mail) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)){
      return true;
    }
    alert("Invalid email address");
    return false;
  }


  /**
   * calls the login.service file to submit the email to firebase
   */
  submitEmail(email: string) {
    let isValid = this.ValidateEmail(email);

    if (!(this.previousEmails.indexOf(email) > -1) && isValid) {
      this.service.submitKeepMeUpdated(email);
      this.previousEmails.push(email);
      this.displayConfirm = "flex";
    }
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';

import {AngularFireModule} from "@angular/fire";
import {AngularFirestore, AngularFirestoreModule} from "@angular/fire/firestore"

import {AngularFontAwesomeModule} from "angular-font-awesome";

//Angular Google Maps imports
import { AgmDirectionModule } from "agm-direction";
import { AgmCoreModule, AgmMap } from '@agm/core';
import { HttpClientModule } from "@angular/common/http";
import { RouterModule, ActivatedRoute } from "@angular/router";

//Components
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { LoggedInComponent } from './logged-in/logged-in.component';
import { Error404Component } from './error404/error404.component';
import { LandingComponent } from "./landing/landing.component";
import { AboutComponent } from './about/about.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ServiceForClinicsComponent } from './service-for-clinics/service-for-clinics.component';
import { ServiceForPatientsComponent } from './service-for-patients/service-for-patients.component';
import { LandingFooterComponent } from './landing-footer/landing-footer.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { CampaignFormComponent } from './campaign-form/campaign-form.component';
import { CampaignDemoComponent } from './campaign-demo/campaign-demo.component';
import { ProductComponent } from './product/product.component';
import { ContactComponent } from './contact/contact.component';
import { LandingHeaderComponent } from './landing-header/landing-header.component';
import { RedirectLandingComponent } from './redirect-landing/redirect-landing.component';


@NgModule({
  declarations: [
    AppComponent,
    LoggedInComponent,
    MainComponent,
    Error404Component,
    LandingComponent,
    AboutComponent,
    TermsOfServiceComponent,
    PrivacyPolicyComponent,
    ServiceForClinicsComponent,
    ServiceForPatientsComponent,
    LandingFooterComponent,
    FeedbackComponent,
    CampaignFormComponent,
    CampaignDemoComponent,
    ProductComponent,
    ContactComponent,
    LandingHeaderComponent,
    RedirectLandingComponent
  ],
  imports: [
    RouterModule.forRoot([
      {
        path:"clinic/:name/:code",
        component: LoggedInComponent
      },
      {
        path:"clinic/:name",
        component: MainComponent
      },
      {
        path: "",
        component: RedirectLandingComponent
      },
      {
        path: "about",
        component: AboutComponent
      },
      {
        path: "product",
        component: ProductComponent
      },
      {
        path: "contact",
        component: ContactComponent
      },
      {
        path: "termsofservice",
        component: TermsOfServiceComponent
      },
      {
        path: "privacypolicy",
        component: PrivacyPolicyComponent
      },
      {
        path: "forclinics",
        component: ServiceForClinicsComponent
      },
      {
        path: "forpatients",
        component: ServiceForPatientsComponent
      },
      {
        path: "&nbsp",
        component: LandingComponent
      },
      {
        path: "&nbsp;",
        component: LandingComponent
      },
      {
        path: "feedback",
        component: FeedbackComponent
      },
      {
        path: "wait-less",
        component: CampaignFormComponent
      },
      {
        path: "demo",
        component: CampaignDemoComponent
      },
      { // this component must be at the end of the array
        path: "**",
        component: Error404Component
      }
    ]),
    BrowserModule,
    AngularFontAwesomeModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AngularFireModule.initializeApp({
        apiKey: "AIzaSyBR_GAs4xp85vws7fP-UFV-DspnjrnUiTM",
        authDomain: "waivethewait.firebaseapp.com",
        databaseURL: "https://waivethewait.firebaseio.com",
        projectId: "waivethewait",
        storageBucket: "waivethewait.appspot.com",
        messagingSenderId: "993365596709",
        appId: "1:993365596709:web:723e02b1e9b99d707c3dcc",
        measurementId: "G-SCGRNGTN6D"
      }
    ),
    AngularFirestoreModule,
    //Agm Core is for the google maps and is connected to Daniel's personal account
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBR_GAs4xp85vws7fP-UFV-DspnjrnUiTM',
      libraries: ['places']
    }),
    AgmDirectionModule,
  ],
  providers: [MainComponent, LoggedInComponent],
  bootstrap: [AppComponent],
})
export class AppModule { }

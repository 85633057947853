import { Injectable } from '@angular/core';
import { UserModel } from "./models/user.model"
import { LandingSubmission } from "./models/landingSubmission.model"
import { AngularFirestore, AngularFirestoreDocument } from "@angular/fire/firestore";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class LoginService {


  constructor(private firestore: AngularFirestore, private http:HttpClient) { }
  clinic = "intrepid"; // this updates in logged-in.component.ts
  clinic_number;
  info;
  clinicLat:number;
  clinicLng:number;
  loggin = false;
  code = "test";
  address;
  name:string;
  phone:string;

  async updateAddress(address: String){
    await this.firestore.collection(this.clinic.replace(" ", "")).doc(this.code).update({patientStartAddress: address});
  }

  async getIsClinicClosed() {
    let data;
    await this.firestore.collection(this.clinic.replace(" ", "")).doc("clinicInfo").get().toPromise().then(res => data = res);
    //for some reason, typeof(data.data().closed) is not a boolean when i was returning it
    if (data.data().closed) {
      return true;
    }
    else {
      return false;
    }
  }

  async checkURL(){
    let data;
    await this.firestore.collection(this.clinic.replace(" ", "")).doc("clinicInfo").get().toPromise().then(res => data = res);
    if(data.data() !== undefined){
      this.loggin = true;
      this.name = data.data().name;
      this.phone = data.data().phone;

    }
    else{
      this.loggin = false;
    }
  }

  async checkUser(user:string, phone){
    let data;
    await this.firestore.collection(this.clinic.replace(" ", "")).doc(user).get().toPromise().then(res => data = res);
    let dat = data.data();
    if(dat !== undefined){
      if(data.data().patientPhoneNumber == phone){
        this.loggin = true;
      }
      else{
        this.loggin = false;
      }
    }
    else{
      this.loggin = false;
    }
  }

  async loggedCheckUser(user:string){
    let data;
    await this.firestore.collection('patientLookup').doc(user).get().toPromise().then(res => data = res);
    if(data.data() !== undefined){
      this.loggin = true;
      this.code = data.data().secretCode;
      this.getUser(data.data()['secretCode']);
      await this.serviceSignIn(data.data()['secretCode']);
    }
    else{
      this.loggin = false;
    }
  }
  user;
  getUser(code:string = "test") {
    this.user = this.firestore.collection(this.clinic).doc(code).snapshotChanges().pipe(
      map(actions => {
        const data = actions.payload.data();
          if (typeof data !=='undefined') {
            // @ts-ignore
            return {...data} as UserModel;
          }
      })
    );
  }

  async serviceSignIn(code: string){
    this.code = code;
    let data;
    await this.firestore.collection(this.clinic.replace(" ", "")).doc("clinicInfo").get().toPromise().then(res => data = res);
    this.clinicLat = data.data().locationLat;
    this.clinicLng = data.data().locationLng;
    this.name = data.data().name;
    this.phone = data.data().phone;
    this.clinic_number = data.data().phone;
    return data;
  }


  //submits the patient into the firebase
  async joinQueue(phone: number, name: string){
    let user:UserModel = {
      patientName: "dd",
      secretCode: "ew",
      // emailNotifActive: false,
      patientPhoneNumber: 3,
      predictedWaitTime: 3,
      clinicName: this.clinic,
      textNotifActive: false,
      status: "no-action",
      patientStartAddress:"",
      patientAppointmentTime: "Being setup",
      addedByPatientFrontEnd: true,
      createdByPatientFrontEnd: true,
      fieldsChangedByFrontend: {},
      Delay: false,
      prescreeningPassed: "unknown"
    };

    let url = "https://us-central1-waivethewait.cloudfunctions.net/genName?database="+this.clinic.replace(" ", "");
    await this.http.get(url).toPromise().then(res => user.secretCode = res["Name"]).catch((err:HttpErrorResponse)=>console.log(err));
    if (user.secretCode == "Nothing"){
      // consol.log'("this isn't a thing");
    }
    user.patientPhoneNumber = phone;
    let time;
    await this.firestore.collection(this.clinic.replace(" ", "")).doc("clinicInfo").get().toPromise().then(res => time = res);
    user.predictedWaitTime = time.data().predictedTime;
    user.patientName = name;
    let id = user.secretCode.replace(" ", "").toLowerCase();
    this.code = user.secretCode;
    user.clinicName = this.clinic;
    // user.patientEmailAddress="";

    let data;
    await this.firestore.collection(this.clinic.replace(" ", "")).doc("clinicInfo").get().toPromise().then(res => data = res);
    this.clinicLat = data.data().locationLat;
    this.clinicLng = data.data().locationLng;
    user.predictedWaitTime = data.data().predictedWaitTime;
    this.clinic_number = data.data().phone;


    this.firestore.collection(this.clinic.replace(" ", "")).doc(id).set(user);
    this.getUser(id);
    await this.linkVer(id, user.patientPhoneNumber, user.patientName);
  }

  async linkVer(code:string, phone, name){
    let s:String="";
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (let i = 0; i<4; i++){
      s += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    let final_code = code+s;
    await this.firestore.collection('patientLookup').doc(final_code).set({secretCode:code, clinicName:this.clinic});
    let url = "https://us-central1-waivethewait.cloudfunctions.net/sendText?database="+this.clinic.replace(" ", "")+"&phone="+phone+"&name="+name+"&link="+final_code+"&type="+2;
    await this.http.get(url).toPromise().then(res => console.log(res)).catch((err:HttpErrorResponse)=>console.log(err));
  }


  async changeTextNotif(textNotif: boolean){
    let code = this.code.replace(/\s/g, '').toLowerCase();
    await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).update({textNotifActive: textNotif});
  }

  // async changeEmailNotif(emailNotif: boolean){
  //   let code = this.code.replace(/\s/g, '').toLowerCase();
  //   await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).update({emailNotifActive: emailNotif});
  //
  // }
  //
  async updateStatus(status){
    let code = this.code.replace(/\s/g, '').toLowerCase();
    await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).update({status:status})
  }

  async setCommute(type, time){
    let code = this.code.replace(/\s/g, '').toLowerCase();
    await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).update({[type]:time})
  }

  async getCommuteType(){
    let code = this.code.replace(/\s/g, '').toLowerCase();
    let dat;
    await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).get().toPromise().then(data => dat = data);
    return dat.data().commuteType;
  }

  async getStatus(){
    let code = this.code.replace(/\s/g, '').toLowerCase();
    let dat;
    await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).get().toPromise().then(data => dat = data);
    return dat.data().status;
  }

  async getAddress(){
    let code = this.code.replace(/\s/g, '').toLowerCase();
    let dat;
    await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).get().toPromise().then(data =>dat= data);
    return dat.data().patientStartAddress;
  }

  async getCarCommute(){
    let code = this.code.replace(/\s/g, '').toLowerCase();
    let dat;
    await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).get().toPromise().then(data => dat= data);
    return dat.data().carCommuteTime;
  }

  async getFormAnswers(){
    let code = this.code.replace(/\s/g, '').toLowerCase();
    let dat;
    await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).get().toPromise().then(data => dat= data);
    return dat.data().prescreeningAnswers;
  }

  async getApptTime() {
    let code = this.code.replace(/\s/g, '').toLowerCase();
    let dat;
    await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).get().toPromise().then(data => dat= data);
    return dat.data().patientAppointmentTime;
  }

  async setCommuteType(type){
    let code = this.code.replace(/\s/g, '').toLowerCase();
    await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).update({commuteType:type, latest_change: "frontend"})
  }

  async updateForm(prescreeningResult, prescreeningAnswers){
    let code = this.code.replace(/\s/g, '').toLowerCase();
    if (this.clinic == "kis") { // change the "latest_change" field if we're working with KIS
      await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).update({prescreeningPassed: prescreeningResult, prescreeningAnswers: prescreeningAnswers, latest_change: "frontend"})
    }
    else {
      await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).update({prescreeningPassed: prescreeningResult, prescreeningAnswers: prescreeningAnswers})
    }
  }

  async setHCExpiry(HCExpiryDate){
    let code = this.code.replace(/\s/g, '').toLowerCase();
    await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).update({fromFirebaseHCExpiry:HCExpiryDate})
  }

  async getHCExpiry() {
    let code = this.code.replace(/\s/g, '').toLowerCase();
    let dat;
    await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).get().toPromise().then(data => dat= data);
    return dat.data().fromFirebaseHCExpiry;
  }

  async setHCLastTwoVals(HCLastTwoVals){
    let code = this.code.replace(/\s/g, '').toLowerCase();
    await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).update({fromFirebaseHCLastTwoVals:HCLastTwoVals})
  }

  async getHCLastTwoVals() {
    let code = this.code.replace(/\s/g, '').toLowerCase();
    let dat;
    await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).get().toPromise().then(data => dat= data);
    return dat.data().fromFirebaseHCLastTwoVals;
  }

  async setInfoFormSubmittedStatusTrue() {
    let code = this.code.replace(/\s/g, '').toLowerCase();
    await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).update({infoFormSubmitted:true})
  }

  async didPatientSubmitInfoForm() {
    let code = this.code.replace(/\s/g, '').toLowerCase();
    let dat;
    await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).get().toPromise().then(data => dat= data);
    return dat.data().infoFormSubmitted;
  }

  async getPatientEmail() {
    let code = this.code.replace(/\s/g, '').toLowerCase();
    let dat;
    await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).get().toPromise().then(data => dat= data);
    return dat.data().patientEmailAddress;
  }

  async getPatientPhoneNumber() {
    let code = this.code.replace(/\s/g, '').toLowerCase();
    let dat;
    await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).get().toPromise().then(data => dat= data);
    return dat.data().patientPhoneNumber;
  }

  async getPatientHomeAddress() {
    let code = this.code.replace(/\s/g, '').toLowerCase();
    let dat;
    await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).get().toPromise().then(data => dat= data);
    return dat.data().patientHomeAddress;
  }

  async getValidInfo() {
    let code = this.code.replace(/\s/g, '').toLowerCase();
    let dat;
    await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).get().toPromise().then(data => dat= data);
    return dat.data().validInfo;
  }

  async setValidInfo(value: boolean) {
    let code = this.code.replace(/\s/g, '').toLowerCase();
    await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).update({validInfo:value})
  }

  async logUserInfo(logTimeString) {
    let code = this.code.replace(/\s/g, '').toLowerCase();
    let dat;
    await this.firestore.collection(this.clinic.replace(" ", "")).doc(code).get().toPromise().then(data => dat= data);
    await this.firestore.collection("logging").doc(logTimeString).set(dat.data());
  }

  async getClinicAddress() {
    let code = this.code.replace(/\s/g, '').toLowerCase();
    let dat;
    await this.firestore.collection(this.clinic.replace(" ", "")).doc("clinicInfo").get().toPromise().then(data => dat= data);
    return dat.data().clinicAddress;
  }

  /********************/
  /*Landing Page Stuff*/
  /********************/

  submitFormToFirebase(name, email, institutionName, message) {
    let time = new Date()
    let formSubmission: LandingSubmission = {name: name, email: email, institutionName: institutionName, message: message, timeStamp: time};

    this.firestore.collection("landing_forms").doc(email).set(formSubmission);
  }
  /**
   * handles the submitting of emails from the Keep Me Updated input box in the landing footer
   * @param email: a string to be submitted to firebase
   */
  submitKeepMeUpdated(email) {
    let time = new Date()
    // set with only the timestamp
    this.firestore.collection("landing_keep_updated").doc(email).set({timeStamp: time});
  }
}


//todo form not working when you first sign up
